const Categories = () => {
    return(
        <section className="section section-categories">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="Header-title">
                            <h2 className="text-weight-normal pb-2 border-bottom px-0 mb-5 text-weight-normal text-dm">EXPLORE OUR <span className="text-weight-bold">CATEGORIES</span></h2>
                        </div>
                        <div  className="mb-5">
                            <p className="text-questrial text-size-20">We Specialise in Supplying Quality Designer Woven Labels to the Schools, Security, Petrolium, Tailors, Garment Industry etc.</p>
                            <p className="text-questrial text-size-20">Our woven labels to add the finishing touches to their garments that reflects the quality of their products and the style of their brand.</p>
                        </div>

                        <div className="section__body">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                                    <div className="category">
                                            <div className="category-image">
                                                <div className="category-background" style={{'backgroundImage' : 'url(images/category/label.png)'}}></div>
                                            </div>
                                            <div className="category-content">
                                                <p>Labels</p>
                                            </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                                    <div className="category">
                                        <div className="category-image">
                                            <div className="category-background" style={{'backgroundImage' : 'url(images/category/belt.png)'}}></div>
                                        </div>

                                        <div className="category-content">
                                            <p>School Belt</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                                    <div className="category">
                                        <div className="category-image">
                                            <div className="category-background" style={{'backgroundImage' : 'url(images/category/tie.png)'}}></div>
                                        </div>

                                        <div className="category-content">
                                            <p>School Tie</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Categories;