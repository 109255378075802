import { Link } from 'react-router-dom';

const Footer = () => {
    return(
        <>
            <div className="bottom-container">
                <div className="footer-bar">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="list-details">
                                    <li className="my-3">
                                        <span className="text-questrial">Best Quality</span>
                                    </li>

                                    <li className="my-3">
                                        <span className="text-questrial">Fast Service</span>
                                    </li>

                                    <li className="my-3">
                                        <span className="text-questrial">More Options</span>
                                    </li>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <footer className="d-flex flex-column-reverse flex-md-row flex-wrap justify-content-between align-items-center py-3 my-4  border-top">
                        <p className="col-md-4 mb-0 text-muted">Copyright © 2022 Jalaram label.</p>
                        <Link to='/' className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
                            <img src="images/footer-logo.png" alt="footer-logo" />
                        </Link>

                        <ul className="nav col-md-4 justify-content-end">
                            <li className="nav-item">
                                <Link to='/contact' className="nav-link px-2 text-muted">Contact us</Link>
                            </li>
                        </ul>
                    </footer>
                    {/* <p className="float-end">
                        <a href="#"><img src="images/scroll-top-new.png" alt="scroll-top" /></a>
                    </p> */}
                </div>
            </div>
        </>
    );
}
export default Footer;