import 'bootstrap/dist/css/bootstrap.css';
import Layout from './Layout';

function App() {
    return (
        <Layout />
    );
}

export default App;
