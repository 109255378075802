import React from 'react';
import Home from './Page/home';
import Contact from './Page/contact';
import { Routes, Route } from "react-router-dom";

const Content = () => {
    return(
        <>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/contact" element={<Contact />} />
            </Routes>
        </>
    );
}
export default Content;