import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
    return(
        <>
            <header>
                <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-primary-platform">
                    <div className="container-fluid">
                        <Link to='/' className="navbar-brand m-auto m-md-left">
                            <img src="images/header-logo-vertical-1.png" alt="header-logo" className="d-flex" />
                        </Link>

                        <button className="navbar-toggler d-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse d-none d-md-flex justify-content-end" id="navbarCollapse">
                            <ul className="navbar-nav ml-auto mb-2 mb-md-0">
                                <li className="nav-item">
                                    <Link to='/contact' className="nav-link active">Contact us</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
}
export default Header;