const OurProcess = () => {
    return (
        <>
            <div className="px-4 pt-5 mt-5 process-section">
                <div className="container">
                    <div className="Header-title">
                        <h2 className="text-weight-normal pb-2 border-bottom px-0 text-weight-normal text-dm">OUR <span className="text-weight-bold">PROCESS</span></h2>
                    </div>
                    <div className="row g-4 py-5 row-cols-1 row-cols-lg-3 mb-0">
                        <div className="col d-flex align-items-start">
                            <div className="icon-square text-dark flex-shrink-0 me-3">
                                <img src="images/svg/info-1.svg" alt="icon description" className="icon mt-1" width="40" />
                            </div>
                            <div className="text-blue">
                                <h2 className="text-raleway text-blue">Get in touch</h2>
                                <p className="text-questrial">Initial quote request comes through, it is processed and tested to see if it fits our requirements. Once this has been established, we will request artwork and product specifications if it hasn’t already been provided.</p>
                            </div>
                        </div>
                        <div className="col d-flex align-items-start">
                            <div className="icon-square text-dark flex-shrink-0 me-3">
                                <img src="images/svg/info-2.svg" alt="icon description" className="icon mt-1" width="40" />
                            </div>
                            <div className="text-blue">
                                <h2 className="text-raleway text-blue">Quotation</h2>
                                <p className="text-questrial">Once this information has been provided, we can then provide you with an exact quote and details on how to proceed. We then take a small deposit to produce a sample, which will be emailed or whatsApp to you prior to production to ensure that you’re happy with the results.</p>
                            </div>
                        </div>
                        <div className="col d-flex align-items-start">
                            <div className="icon-square text-dark flex-shrink-0 me-3">
                                <img src="images/svg/info-3.svg" alt="icon description" className="icon mt-1" width="40" />
                            </div>
                            <div className="text-blue">
                                <h2 className="text-raleway text-blue">Production</h2>
                                <p className="text-questrial">Following approval,
                                Finally approved design go into production. Once made, labels are inspected and go through rigorous quality control. They are then sent to you via courier to ensure you get your labels within 10-15 business days in a timely manner!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OurProcess;