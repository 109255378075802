//import 'bootstrap/dist/css/bootstrap.css';
import Header from './Sections/header';
import Footer from './Sections/footer';
import Content from './Sections/content';
import { BrowserRouter } from "react-router-dom";

const Layout = () => {
    return (
        <div id="wrapper">
            <div className="wrapper-inner">
                <main id="main" role="main">
                    <BrowserRouter>
                        <Header />
                        <Content />
                        <Footer />
                    </BrowserRouter>
                </main>                
            </div>
        </div>
    );
}

export default Layout;
