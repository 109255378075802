import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from 'react-router-dom';

const Contact = () => {
    return (   
            <>
                <div className="d-block mx-lg-auto img-fluid contact-banner">
                    <section className="hero row">
                        <div className="page-breadcrumb col-md-6 col-12 offset-md-1" >
                            <li><Link to='/' className="text-white text-decoration-none">Home</Link></li>
                            <li><Link to='/contact' className="text-white text-decoration-none h6">Contact us</Link></li>
                        </div>
                        <div className="clearfix"></div>
                        <div className="col-md-5 section-title col-12 offset-md-1">Interested in learning more?</div>
                    </section>
                </div>
                <section className="text-1 container">
                    <div className="row">
                        <div className="col-md-3 offset-md-1 my-5 title d-none d-md-flex"> Contact us </div>
                        <div className="col-md-6 my-5 offset-md-1 overview-content">
                            <div className="content">
                                <p>Please see below our contact details.</p>
                                <div className="contact-details">
                                    <span className="d-block mb-3"><i className="bi bi-geo-alt-fill"></i> Address: Kothariya solwant, Gondal highway, Opp. Parin furniture - Rajkot</span>
                                    <span className="d-block mb-3"><i className="bi bi-phone-fill"></i> Phone: (+91) 9979933651, (+91) 9925820010</span>
                                    <span className="d-block mb-3"><i className="bi bi-facebook"></i>Facebook: <a href="https://www.facebook.com/Jalaram-label-720076371457786" target="_blank" rel="noopener noreferrer">Jalaram label</a></span>
                                    <span className="d-block mb-3"><i className="bi bi-envelope"></i>E-mail: <a href = "mailto: jalaramlabel@gmail.com">jalaramlabel@gmail.com</a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
}

export default Contact;