import React,{useEffect, useCallback} from 'react';
import Slider from './SubSections/slider';
import Categories from './SubSections/categories';
import OurProcess from './SubSections/ourProcess';
import CategorySlider from './SubSections/categorySlider';

const Home = () => {
    const resetWindowScrollPosition = useCallback(() => window.scrollTo(0, 0), [])
    useEffect(() => {
        window.onbeforeunload = function () {
            resetWindowScrollPosition()
        }
    }, [resetWindowScrollPosition])
    return(
        <>
           <Slider />
           <OurProcess />
           <Categories />
           {/*<CategorySlider />*/}
        </>
    );
}
export default Home;